.cardContents{
    display: flex;
    flex-wrap: wrap;
    /*justify-content: space-evenly;*/
    margin-top: 0px;
}
.card{
    border: 1px solid grey;
    border-radius: 10px;
    width: fit-content;
    margin: 10px;
}