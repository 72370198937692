.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /*width: 100vw;*/
    height: 100vh;
    overflow: auto;
    min-width: 306px;
    background-color: #f0eee1;
}

.login-box {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100vh;
}