.quickUsDetailsContainer {
    overflow-y: scroll;
    width: 99%;
    height: 100%;
    padding: 10px;
}

.quickUsDetailsGrid {
    display: grid;
    grid-template-columns: 1fr;
    width: 99%;
    grid-row-gap: 2px;
    margin: 10px 0;
    padding: 2px 0;
}

.quickUsDetailsGrid img {
    width: 18px;
    height: 18px;
}

.quickUsDetailsGrid div {
    display: flex;
    flex-direction: row;
}

.quickViewWidth {
    min-width: 360px;
}